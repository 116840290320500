import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Divider, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/aziz.aac'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/dinny/logoig.svg'
import gold5 from '../assets/img/gold5.png'
import groom from '../assets/img/groom.png'
import burung from '../assets/img/dinny/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/wicky/covid.svg'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#B99225'
let black = 'rgb(38,38,38)'

export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()

    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      hadir: true,
      err: [],
      submitted: '',
      sesi: 2
    }
  }

  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
    var countDownDate = new Date("12/12/2020").getTime();

    // Update the count down every 1 second
    var x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })


    }, 1000);

  }

  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/aac';
    snd.play();
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('top');
      elmnt.scrollIntoView();
    }, 1000)
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir} ${this.state.hadir?"sesi:"+this.state.sesi:''}", jumlahhadir: "", kepada: "wulan-wicky", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }


  }
  render() {
    let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
    let slide = ["	https://i.ibb.co/zV08Cfb/1.jpg	",
    "	https://i.ibb.co/Wx9rfTG/25b0baad-75b6-4b47-9df2-7855880ab6ea.jpg	",
    "	https://i.ibb.co/bmjM71x/3.jpg	",
    "	https://i.ibb.co/XprwbDF/7b7fa7da-abdf-445f-9d07-5f394cda68a4.jpg	",
    "	https://i.ibb.co/4fg6bcC/5.jpg	",
    "	https://i.ibb.co/QFPRvp9/6.jpg	",
    "	https://i.ibb.co/2cqLn0g/7.jpg	",    
    ]
    let slider = []
    slide.map(v => {
        slider.push(gambar(v, 95,'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
    })
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''

    return (
      <>
        <Helm
          title='Undanganku - Wulan & Wicky'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={slide[0]}
          url='https://undanganku.me/wulan-wicky'
        />

        <div id='gold5' style={{
          backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
          backgroundSize: 'cover', backgroundPosition: 'center'
        }}>
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw',
            backgroundColor: 'transparent'
          }}>
            <Container fluid id='g3-header' className='relative' style={{
              backgroundImage: `url('https://i.ibb.co/58jsf2J/modal.jpg')`
            }}>
              <Item>
                <Col xs={12} md={4} className='m-2 m-md-0 '>
                  <img className='img-fluid w-100 p-2'
                    src={gambar('https://i.ibb.co/JdFV9kF/Logo.png')} data-aos="fade-left" />
                </Col>
              </Item>
              <Item>
                {
                  <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                    Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                }</Item>
              <Row className='justify-content-center'>
                <div onClick={() => { this.play() }}
                
                  className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                  style={{ marginTop: 0 ,color:'white'}}>
                  Open Invitation
                            </div>
              </Row>
            </Container>

            <div className={hide ? 'd-none' : 'd-block'}>
              <div id="top" style={{ backgroundColor: 'transparent' }}>
                <Container className="dinny px-3 pt-5 ">
                  <Item>
                    
                    <p className="fs16 text-center cblack px-3">
                     
                      
                     Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan 
                     pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa 
                     tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, 
                     pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum
                      yang berpikir. <br/><br/>(Ar- Rum 21)

                    </p>
                  </Item>
                  <Item>
                      <Col xs={6} sm={2}>
                        <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                            <p className='text-center p-2 px-4 ' style={{color:'#B99225'}}>
                            Pernikahan Wulan & Wicky
                              </p>
                          </Item>
                </Container>
                <Container id='sectiongold55' className="py-5 dinny" >

                  <Item>
                    <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: '#B99225' }}>
                            Wulan
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar("https://i.ibb.co/mb8v3RP/823cdab0-8551-41fe-8c31-6ac3baa33a53.jpg", 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: '#B99225' }}>
                          Wulan Anggraeni
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>Putri dari:</b><br />
                        Bpk. H. Iwa Koswara  <br />
                        &<br />
                       Ibu Hj. Tety Banowati
                      </p>
                        </Item>
                        <Item>
                          
                            <img src={logoig} className='btn p-0' 
                            onClick={() => { window.open('https://instagram.com/wulbul') }} width="35px" height="35px" />
                          
                        </Item>
                      </div>
                    </div>
                    <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: '#B99225' }}>
                            Wicky
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar("https://i.ibb.co/NtzKJ7n/46108f65-fd29-4d7a-bbe3-90a8cf4fee02-1.jpg", 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: '#B99225' }}>
                          Wicky Dwitama
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>Putra dari:</b><br />
                        Bpk. Andang Purwantoro  
                        <br />
                        &<br />
                        Ibu Elly Ahdiani
                      </p>
                        </Item>
                        <Item>
                        <img src={logoig} className='btn p-0'
                         onClick={() => { window.open('https://instagram.com/wickytama') }}  width="35px" height="35px" />
                          
                        </Item>
                      </div>
                    </div>
                  </Item>
                </Container>
                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                  <Item>
                    <p className="fs16">
                      Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20">
                      <b>
                        SABTU <span className="fs36">12</span> DES 2020
                      </b>
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20 col-sm-4" style={{ color: cmain }}>
                      <b>Akad Nikah </b><br />
                      <span className="cblack fs16">
                        08.00 WIB
                      </span>
                      {/* <br />
                      <span className="cblack fs12">
                        (Hanya dihadiri oleh keluarga)
                      </span> */}

                    </p>
                    <p className="px-3 d-none d-sm-block" style={{ color: cmain, fontSize: '72px' }}>
                      \
                    </p>
                    <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                    </div>
                    <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: cmain }}>
                      <b>Resepsi</b><br />
                      <span className="cblack fs16">
                      Sesi 2 : 11.00 - 12.00 WIB
                      </span> <br />
                      <span className="cblack fs16">
                      Sesi 3 : 12.00 - 13.00 WIB
                      </span>

                      <span className="cblack fs12">
                        
                      </span>
                    </p>
                  </Item>
                  <Item>
                    <p className="fs16 pt-3">
                      <b>deBraga by Artotel (Meetspace Lt.2)

</b> <br />
                      <span >
                      Jl.Braga No.10 Bandung
                    </span>
                    </p>
                  </Item>
                  <Item>
                      <p style={{fontSize:'16px',fontStyle:'italic'}}>Undangan Hanya untuk 2 orang</p>
                  </Item>
                  <Item>
                    <Col xs={10} sm={3}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      onClick={() => {
                        window.open("https://goo.gl/maps/6aPuy8hqrDu98uML6")
                      }}
                      className="p-2 mx-sm-2 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Get Direction</b>
                        </p>
                      </Item>
                    </Col>
                    <Col 
                      onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=MTNydjc2bjh0ZTVhbjA2M2I4aHNma2doZ3YgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                      xs={10} sm={3}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Add to Calendar</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>
                <Container id='sectiongold57'>
                  <div className='pt-3'>

                    <Item>
                      <div data-aos="fade-left" data-aos-duration="1000"
                        className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                        <Item>
                          <div className='item'>
                            <Item>
                              <div>
                                {days}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Days
                      </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {hours}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Hours
                      </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div >
                                {minutes}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Mins
                      </span>
                            </Item>
                          </div>
                          <div className='dot' >:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {seconds}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Secs
                      </span>
                            </Item>
                          </div>
                        </Item>

                      </div>
                    </Item>
                  </div></Container>
                <Container className="text-center py-5 dinny" id="dinny">
                  <>
                    <Item>
                      <h1 style={{
                        fontSize: '72px',
                        fontFamily: "'Marck Script', cursive",
                        color: '#B99225'
                      }}>
                        Rundown 
                          </h1>
                    </Item>

                    <Item>
                      <Col sm={2} xs={4}>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg" className="img-fluid w-100 p-2" />
                      </Col>
                    </Item>
                    <Item>
                      <p className="cblack fs16">
                        <b>
                          08.00
                        </b><br/>
                        Akad Nikah
                      </p>
                    </Item>
                    <Item>
                      <Col sm={4} className="pt-sm-3">
                        <Item>
                          <Col xs={8} className="order-2 order-sm-1">
                            <p className="cblack text-left text-sm-right fs16">
                              <b>
                                11.00 - 11.15
                              </b><br />
                              Tamu undangan dipersilahkan masuk

                            </p>
                          </Col>
                          <Col sm={4} xs={3} className="order-1 order-sm-2">
                            <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                          </Col>
                        </Item>
                        <div className="d-block opa">
                          <Item>
                            <Col xs={3} sm={4} >
                              <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                            </Col>
                            <Col xs={8}>
                              <p className="cblack text-left fs16">
                                <b>
                                  11.00 - 11.30
                              </b><br />
                              Sesi foto

                            </p>
                            </Col>
                          </Item>
                        </div>

                        <Item>
                          <Col xs={8} className="order-2 order-sm-1">
                            <p className="cblack text-left text-sm-right fs16">
                              <b>
                                11.00 - 13.00
                              </b><br />
                              Walimatul ‘ursy

                            </p>
                          </Col>
                          <Col sm={4} xs={3} className="order-1 order-sm-2">
                            <img src="https://www.flaticon.com/svg/static/icons/svg/703/703213.svg" className="img-fluid w-100" />
                          </Col>
                        </Item>

                        
                      </Col>


                      <Col sm={4} className="pt-sm-3">

                        <div style={{ opacity: 0 }} className="d-none d-sm-block">
                          <Item>
                            <Col sm={8}>
                              <p className="cblack text-right fs16">
                                <b>
                                11.00 - 11.15
                              </b><br />
                              Tamu undangan dipersilahkan masuk

                            </p>
                            </Col>
                            <Col sm={4}>
                              <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                            </Col>
                          </Item>
                        </div>
                        <div>
                          
                        </div>
                        <div className="d-none d-sm-block">
                        <Item>
                          <Col sm={4} xs={3}>
                            <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                          </Col>
                          <Col xs={8} >
                            <p className="cblack text-left fs16">
                              <b>
                                11.00 - 11.30
                              </b><br />
                              Sesi foto

                            </p>
                          </Col>

                        </Item>
                        </div>
                        <div style={{ opacity: 0 }} className="d-none d-sm-block">
                          <Item>

                            <Col sm={8}>
                              <p className="cblack text-right fs16">
                                <b>
                                  11.00 - 13.00
                              </b><br />
                              Walimatul ‘ursy

                            </p>
                            </Col>
                            <Col sm={4}>
                              <img src="https://www.flaticon.com/svg/static/icons/svg/703/703213.svg" className="img-fluid w-100" />
                            </Col>
                          </Item>
                        </div>



                        

                      </Col>
                    </Item>
                  </>
                </Container>

                <Container className="py-3">
                  <Item>
                    <Col xs={12} md={6}>
                      <img src={covid} className="w-100 img-fluid" />
                    </Col>
                  </Item>
                </Container>
                {/* <Container className="text-center dinny">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: '#B99225'
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className="cblack text-center w-100 fs16">
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Gunakan Masker
                        </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              jaga Jarak
                      </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Cukup Bersalaman tanpa Bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container> */}


                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                  <Slider slide={slider} />
                </Container>
                <Container id='sectiongold56'>
                  <div className='pt-3'>
                    
                    <div data-aos={`fade-right`} data-aos-duration="2000">
                      <Item>
                        <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                          <Item>
                            <p className='text-center p-2 px-4 fs14'>
                            Jika Allah berkehendak menyatukan dua hati, maka keduanya pasti akan bersatu walaupun diantara keduanya terbentang langit dan bumi.
</p>
                          </Item>
                        </div>

                      </Item>
                    </div>
                  </div>
                </Container>

                <Container id='sectiongold58' >

                  <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                    <Item>
                      <Col xs={4} lg={2}>
                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                      <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                        <Item>
                          <h1 className="w-100 text-center" style={{
                            fontFamily: '"Marck Script", cursive',
                            color: 'rgb(185, 146, 37)'
                          }}>
                            Doa Untuk Kedua Mempelai
                    </h1>
                        </Item>
                        <Item>
                          <form className="col-12 w-100">
                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                            <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1">Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"

                                    >Tidak Hadir</label>
                                  </div>
                                  {!hadir ? false : (
                                    <>  <Alert variant='dark col-12 mr-4 '>
                                      <p className="f-14">
                                      Silahkan Pilih Jadwal Kedatangan
                                      </p>
                                      <div onClick={() => {
                                        this.setState({ sesi: 2 })
                                      }
                                      }
                                      className="pl-5">
                                        <input type="radio" id="featured-3" name="sesi" checked={sesi==2 ? true : false} />
                                        <label for="featured-3">
                                        
                                        Sesi 2 : 11.00 - 12.00</label>
                                      </div>
                                      <div onClick={() => {
                                        this.setState({ sesi: 3 })
                                      }
                                      } className="pl-5">
                                        <input type="radio" id="featured-4" name="sesi" checked={sesi==3 ? true : false} />
                                        <label for="featured-4"

                                        >Sesi 3 : 12.00 - 13.00</label>
                                      </div>
                                      <p className="f-14">
                                      Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan

                                      </p>
                                    </Alert>
                                      
                                       
                                                       
                                    </>

                                  )}

                                </div>
                              </div>
                            </Item>

                            <Item>
                              <Col xs={12} className=''>
                                {
                                  submitted == true ? (
                                    <Alert variant='success' style={{fontSize:'16px'}}>
                                      Pesan anda sudah disampaikan
                                    </Alert>) : (submitted === false ? (
                                      <Alert variant='danger' style={{fontSize:'16px'}}>
                                        {
                                          err.map(val => {
                                            return (
                                              <li>{val}</li>
                                            )
                                          })
                                        }

                                      </Alert>
                                    ) : false)
                                }

                              </Col>
                            </Item>
                            <Item>
                              <div className='col-6 button rounded btn'
                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain,color:'white' }} no> Kirim </div>
                            </Item>
                          </form>
                        </Item>
                      </div>
                    </Item>
                  </div>
                </Container>
                <Container className='text-center mb-5' id='sectiongold59'>
                  <Item>
                    <h1 data-aos="zoom-in" data-aos-duration="1000">
                      Possible Wedding
              </h1>
                  </Item>
                  <Item>
                    <h2 data-aos="zoom-in" data-aos-duration="1000">
                      Digital Invitation
              </h2>
                  </Item>
                  <Item>
                    <div className='col-3 col-lg-1'>
                      <img src={logoig} className='img-fluid w-100'
                      />
                    </div>
                  </Item>

                </Container>
              </div>
            </div>
          </div>
        </div>

      </>


    )
  }
}

